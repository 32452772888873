import React, { useState, useRef } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  CardHeader,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import config from '@configFile';
import PageContainer from '@components/PageContainer';
import MostPopular from '@components/MostPopular';
import { isValidEmail, isNameProhibited } from '@utils/index';
import { sanitizeEmail } from '@utils/securityUtils';
import { useAddAPINotification } from '@hooks/useAddAPIEmailNotification';
import Loading from '@components/Loading';

import { useVerifyRecapture, RecaptchaResponse } from '@hooks/useRecaptcha';

const API = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [hasVerified, setHasVerified] = useState<boolean>(false);
  const recaptcha = useRef<ReCAPTCHA>(null);
  const { mutate: verifyRecaptcha } = useVerifyRecapture();

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAccepted(event.target.checked);
  };

  const { mutate: addAPINotification, isLoading } = useAddAPINotification();

  const onChange = (value: string) => {
    verifyRecaptcha(value, {
      onSuccess: (data: RecaptchaResponse) => {
        if (data.success) {
          setHasVerified(true);
        } else {
          // eslint-disable-next-line no-alert
          alert('Please complete the captcha to verify you are not a robot');
        }
      },
      onError: () => {
        // eslint-disable-next-line no-alert
        alert('Please try again later');
      }
    });
  };

  const clickHandler = () => {
    addAPINotification(
      { email, firstName, lastName },
      {
        onSuccess: () => {
          setFirstName('');
          setLastName('');
          setEmail('');
          setSuccess(true);
        },
        onError: () => {
          console.log('Error adding API email notification, please try again');
        }
      }
    );
  };

  const canSubmit =
    termsAccepted &&
    firstName &&
    lastName &&
    email &&
    isValidEmail(email) &&
    !isNameProhibited(firstName.toLowerCase(), lastName.toLowerCase()) &&
    firstName.length <= 50 &&
    lastName.length <= 50 &&
    email.length <= 50 &&
    lastName.length > 1 &&
    firstName.length > 1 &&
    hasVerified;

  const renderForm = () => {
    if (isLoading) {
      return null;
    }

    return (
      <>
        {success ? (
          <Box>
            <Box
              sx={{
                textAlign: 'center',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                pt: 4,
                pb: 4
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  // itallics
                  fontStyle: 'italic'
                }}
              >
                Thank you for signing up!
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  // itallics
                  fontStyle: 'italic',
                  pt: 2
                }}
              >
                We will notify you when our API is available.
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box component="form">
            <TextField
              fullWidth
              label="First Name"
              variant="outlined"
              margin="normal"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              fullWidth
              label="Last Name"
              variant="outlined"
              margin="normal"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
              fullWidth
              label="Email Address"
              variant="outlined"
              margin="normal"
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(sanitizeEmail(e.target.value));
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAccepted}
                  onChange={handleCheckboxChange}
                  color="primary"
                />
              }
              label="I content to an email notification when the API is available."
              sx={{ mt: 1, mb: 2 }}
            />
            <ReCAPTCHA
              ref={recaptcha}
              sitekey={config.RECAPTCHA_SITE_KEY}
              onChange={onChange}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              sx={{ mt: 2 }}
              disabled={!canSubmit}
              onClick={clickHandler}
            >
              Email me when available
            </Button>
          </Box>
        )}
      </>
    );
  };

  return (
    <PageContainer fullWidth>
      <Box m={2}>
        <CardHeader
          title="Amazon Deals — API Access Coming Soon"
          subheader="Sign up to get notified when our API is available"
          sx={{ textAlign: 'center', mb: 3 }}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Why Choose Our API?
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Get the best-selling Amazon deals directly into your system" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Automatically embed your affiliate tag in every link" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Access to clickable coupons and promotions unavailable elsewhere" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Verified and curated deals with rich metadata" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Developer-friendly documentation and quick start guide" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="99.9% uptime guarantee with priority support" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Free tier available" />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              {success ? '' : 'Get notified when our API is available'}
            </Typography>
            {isLoading ? <Loading margin="150px 0 0 0" /> : renderForm()}
          </Grid>
        </Grid>
      </Box>
      <MostPopular />
    </PageContainer>
  );
};

export default API;
